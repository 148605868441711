import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../../../components/Layout'
import HeaderSEO from '../../../components/partials/HeaderSEO'
import Spacing from '../../../components/partials/Spacing'
import { StaticImage } from 'gatsby-plugin-image'
import EventRegistrationForm from '../../../components/EventRegistrationForm'

const EventRegistrationMunich = () => {
    const data = useStaticQuery(graphql`
        query eventRegistrationMunichQuery {
            allContentfulPages(
                filter: { pageReference: { pageIdentifier: { eq: "EventRegistrationMunich" } } }
            ) {
                edges {
                    node {
                        title {
                            title
                        }
                        keywords {
                            keywords
                        }
                        description {
                            description
                        }
                        oGimage {
                            fluid(quality: 100) {
                                src
                            }
                        }
                    }
                }
            }
        }
    `)

    const pageSEO = data.allContentfulPages.edges[0].node

    return (
        <Layout>
            <HeaderSEO pageSEO={pageSEO} />
            <Spacing />
            <div style={{ position: 'relative', display: 'flex', flexWrap: 'wrap' }}>
                <div style={{ width: '100%' }}>
                    <section className='EventRegistration--heroMunich'>
                        <div className='container-1 EventRegistration--hero__content'>
                            <h1 className='cera-pro'>
                                Oktoberfest Special: <br className='onlyDesktop' /> Tapping into real-time insights <br className='onlyDesktop' /> with Apache Kafka®/Druid®
                            </h1>
                            <h2 className='cera-pro'>Featuring Imply + Thinkport</h2>
                            <h3 className='roboto EventRegistration--hero__h3-primary'>
                                Thursday 10th October | 5-8pm
                            </h3>
                            <h3 className='roboto EventRegistration--hero__h3-secondary'>
                                Celonis SE Theresienstraße 6,
                                <br /> 80333 München
                            </h3>
                        </div>
                    </section>
                    <section className='EventRegistration--under-hero'>
                        <div className='container-1'>
                             <p>
                                  Join us for an Apache Kafka® x Apache Druid®
                                  meetup on Thursday, October 10th from 5:30pm
                                  hosted by Lenses!
                             </p>
                            <ul>
                                <li>5:30 pm - 6:00 pm: Welcome and Beer</li>
                                <li>6:00 pm - 6:30 pm: Adam Noonan, Strategic Solutions Engineer, Lenses</li>
                                <li>6:30 pm - 7:30 pm: Hellmar Becker, Senior Sales Engineer, Imply</li>
                                <li>7:30 pm - 8:00 pm: Patrick Polster, Cloud Architect, Thinkport</li>
                                <li>8:00 pm + : Food, Beer and Networking</li>
                            </ul>
                        </div>
                    </section>
                    <div className='onlyMobile'>
                        <div className='EventRegistration--form'>
                            <EventRegistrationForm action='https://go.pardot.com/l/877242/2024-09-23/t1qnkt' />
                        </div>
                    </div>
                    <Spacing />
                    <section className='roboto EventRegistration--feature'>
                        <div className='container-1'>
                            <h4 className='EventRegistration--feature__company cera-pro'>
                                Sessions
                            </h4>
                            <h1 className='EventRegistration--feature__title cera-pro'>
                                Brewing Real-time Data Pipelines <br className='onlyDesktop' /> with Kafka + Druid
                            </h1>
                            <p>
                                Modern event analytics pipelines offer a completely different approach from
                                traditional ETL setups. In this session, we'll showcase how to build an
                                analytics pipeline, using Kafka x Lenses Developer Experience to deploy
                                connectors, operate dataflows and SQL to both explore streaming data & transform
                                it. We'll also dive into Apache Druid for real-time OLAP, demonstrating how to
                                efficiently pre-process, stream, and analyze data in real-time.
                            </p>
                            <p>
                                <span className='EventRegistration--feature__speaker'>
                                    Hellmar Becker, Senior Sales Engineer, Imply <br/>
                                </span>{' '}
                                Hellmar has worked in the software industry for
                                more than 20 years in various pre- and postsales
                                roles. He has worked with large customers in the
                                finance, telco and retail industries, including
                                a full time position at ING Bank for 3 years. He
                                lives near Munich, Germany, with his wife and
                                four kids. In his free time, he likes to read,
                                and to travel with his family.
                            </p>
                            <p>
                                <span className='EventRegistration--feature__speaker'>
                                    Adam Noonan, Strategic Solutions Engineer, Lenses <br/>
                                </span>{' '}
                                Adam is the Lead Solutions Engineer at
                                Lenses.io. A reformed developer, Adam has
                                enjoyed working with government, defense &
                                Fortune 100 customers on big data, AI/ML & data
                                streaming strategies at NetApp, Celonis & now
                                Lenses.io.
                            </p>
                            <h1 className='EventRegistration--feature__title cera-pro'>
                                Mastering the Challenge of <br className='onlyDesktop' /> Multi-Kafka Management
                            </h1>
                            <p>
                                Join us for an insightful deep-dive into the
                                complexities of managing multi-Kafka
                                environments. We'll explore why companies are
                                increasingly operating multiple Kafka providers
                                and clusters, and the unique challenges that
                                arise in such setups. In this session, we'll
                                also introduce an innovative approach, which
                                empowers teams to control and manage multiple
                                Kafka environments from a unified
                                platform. Learn how to streamline operations,
                                optimize performance, and reduce the
                                complexities of scaling Kafka
                                infrastructures. For the first time, see how to
                                seamlessly handle multiple Kafka clusters in one
                                place.
                            </p>
                            <p>
                                <span className='EventRegistration--feature__speaker'>
                                    Patrick Polster, Cloud Architect, Thinkport <br/>
                                </span>{' '}
                                Patrick planned, developed, and operated
                                multiple Event Driven Platforms in the last
                                years. He not only is an enthusiast when it
                                comes down to talking about Kafka but also a
                                Trainer for teams that need to kick-start their
                                Kafka journey or want to deep dive in specific
                                pattern and tool specific questions. As a Cloud
                                Architect he helped multiple companies in
                                Germany to accelerate their Event Driven
                                adventure.
                            </p>
                        </div>
                    </section>
                    <Spacing />
                </div>
                <div style={{ position: 'sticky', right: '20px' }} className='onlyDesktop'>
                    <div className='EventRegistration--form'>
                        <EventRegistrationForm action='https://go.pardot.com/l/877242/2024-09-23/t1qnkt' />
                    </div>
                </div>
            </div>
            <Spacing />
            <section className={' mt-5 mt-sm-0'}>
                <div className='sectionWithGuyBlackStrip' style={{ paddingBottom: '50px' }}>
                    <div className='d-flex flex-wrap align-items-bottom'>
                        <div
                            className='container-1 col-12 text-left mt-3 mb-4'
                            style={{ maxHeight: '170px', paddingLeft: 0 }}>
                            <h2 className='f-40 lh-52 pb-0 text-white'>
                                Build better, sleep easy <br className={'onlyDesktop'} />
                                with the developer experience <br className={'onlyDesktop'} /> for
                                any Kafka
                            </h2>
                            <div className='roll-button navbar-dl-button heroLottieButton'>
                                <a
                                    id='navbar-download'
                                    style={{ fontSize: '1rem' }}
                                    className='text-white mr-0 bg-red'
                                    href='/start/'>
                                    <span data-hover='Start free'>Start free</span>
                                </a>
                            </div>
                        </div>
                        <StaticImage
                            className='pt-2 w-35 sectionWithGuyImage'
                            style={{ left: '50%' }}
                            src='../resources/images/new_homepage/developer-experience.webp'
                            placeholder='tracedSVG'
                            alt='Security'
                        />
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default EventRegistrationMunich
